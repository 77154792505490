<template>
  <div
    class="turntable"
    v-bind="$attrs"
    v-bg="{
      remoteSrc: prizePlateConfig.outerImg,
      localSrc: require('@/assets/turntable-outer.png')
    }"
  >
    <div class="turntable-prize">
      <ul
        class="turntable__inner"
        :class="animatClass"
        v-bg="{
          remoteSrc: prizePlateConfig.innnerImg,
          localSrc: require('@/assets/turntable-inner.png')
        }"
        :style="[animatEnd]"
      >
        <li v-for="(item, index) in prizeList" :key="index" :style="item.style">
          <img :src="item.src" width="100%" />
        </li>
      </ul>
      <div
        class="start-btn"
        v-tracking="tracking"
        @click="onStart"
        v-if="canClickStartBtn"
        v-bg="{
          remoteSrc: prizePlateConfig.pointImg,
          localSrc: require('@/assets/turntable-pointer.png')
        }"
      ></div>
      <div
        class="start-btn"
        v-else
        v-tracking="tracking"
        v-bg="{
          remoteSrc: prizePlateConfig.pointImg,
          localSrc: require('@/assets/turntable-pointer.png')
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { toLink } from '@/utils/index.js'

export default {
  name: 'Turntable',
  props: {
    initUrl: {
      type: Function
    },
    drawUrl: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tracking: {
      type: Object
    }
  },
  data() {
    return {
      prizeList: [],
      rotateState: 'end',
      animatEnd: {},
      prizePlateConfig: '',
      disabledStartBtn: false
    }
  },
  computed: {
    animatClass() {
      return [this.rotateState]
    },
    isDisabled() {
      return this.disabled
    },
    canClickStartBtn() {
      return this.rotateState == 'end' && !this.disabledStartBtn
    }
  },
  methods: {
    onStart() {
      this.$emit('onStart')
      if (this.isDisabled) {
        return
      }
      console.log('onStart')
      this.animatEnd = {}
      this.disabledStartBtn = true
      // 调用抽奖
      this.drawUrl()
        .then(({ data }) => {
          this.$emit('drawAfter', data)
          let { sequenceId, isTrue, skipUrl, prizeId, drawLogId } =
            data.drawResult
          this.resultIndex = sequenceId
          // 转盘停止旋转后的延迟时间
          let waitTime = data.waitTime || 1000
          // 奖品类型 0：电子奖，1：实物奖；3：红包奖
          let prizeType = isTrue
          console.log('isTrue', data, toLink)

          this.rotateState = 'progress'
          setTimeout(() => {
            this.rotateState = 'end'
            let index = this.resultIndex
            let degNum = 360 - ((360 + 22.5 + 45 * (index - 1)) % 360)
            this.animatEnd = { transform: `rotate(${degNum}deg)` }
            setTimeout(() => {
              // 0：电子奖，1：实物奖
              if (prizeType == 0 || prizeType == 1) {
                // 跳转链接(电子奖使用)
                toLink(skipUrl, 'coupons')
              } else if (prizeType == 3) {
                // 红包奖, 只有前置转盘会进入此项
                this.$router.push({
                  name: 'Middle',
                  query: {
                    prizeId,
                    drawLogId
                  }
                })
              }
            }, waitTime)
            // 转盘结束
            this.$emit('rotateStop')
          }, 3000)
        })
        .catch(() => {
          this.disabledStartBtn = false
        })
    },
    init() {
      this.rotateState = 'end'
      this.disabledStartBtn = false
      this.animatEnd = { transform: `rotate(0deg)` }
    }
  },
  created() {
    if (this.initUrl) {
      this.$nextRequest(() => {
        return this.initUrl().then(({ data }) => {
          this.$emit('fetchCb', data)
          this.$store.commit('assets', data)

          this.prizePlateConfig = data.prizePlateConfig
          this.prizePlateConfig.pointImg = data.pointImg

          if (
            data.prizePlateConfig &&
            data.prizePlateConfig.prizePlatConfig &&
            data.prizePlateConfig.prizePlatConfig.length > 0
          ) {
            this.prizeList = data.prizePlateConfig.prizePlatConfig.map(
              (item, index) => {
                return {
                  src: item.prizeImg,
                  style: {
                    transform: `rotate(${(360 - 22.5 + 45 * index) % 360}deg)`
                  }
                }
              }
            )
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.turntable {
  margin: 0 auto;
  width: 660px;
  height: 660px;
  //background: url('../../assets/turntable-panel.gif') no-repeat center top 100% / 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .turntable-prize {
    position: relative;
    width: 600px;
    height: 600px;

    .turntable__inner {
      position: relative;
      width: 100%;
      height: 100%;
      background-position: center top;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      li {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: 50% 50%;
        img {
          position: absolute;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          width: 148px;
        }
      }
    }
  }
  .start-btn {
    position: absolute;
    left: 50%;
    top: 166px;
    transform: translateX(-50%);
    width: 188px;
    height: 247px;
    //background: url('../../assets/pointer.png') no-repeat center top 100% / 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .start {
    animation: rotate 0.6s ease-in;
  }
  .progress {
    animation: rotate 0.2s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
