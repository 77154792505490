<template>
  <div class="m-activity-rules">
    <div class="activity-rules__body">
      <div class="activity-rules__cont">
        <h3>活动规则</h3>
        活动主题：揭盖扫码有奖<br />
        活动时间：即日起至2023年5月31日止<br />
        活动范围：中国大陆地区<br />
        产品范围：盐典饮料促销装<br />
        为感谢广大消费者的支持，即日起至2023年5月31日止，凡在活动开展地区购买盐典饮料促销产品，开盖发现二维码，用手机微信扫描该二维码（识别率99%），即有机会赢取奖品。<br />
        奖品总数120000000个，奖项设置：<br />
        特等奖：黄金酱酒-高尔夫（价值1098元），共100个，中奖率约0.00008333%；<br />
        红包奖：88元红包，共888个，中奖率约0.00074000%；<br />
        红包奖：8.8元红包，共20000个，中奖率约0.01666667%；<br />
        红包奖：0.3元红包，共18000000个，中奖率约15.00000000%；<br />
        圈码好礼：电子奖券，共101979012个，中奖率约84.98251000%。<br />
        兑奖说明：<br />
        1、特等奖：黄金酱酒-高尔夫（价值1098元）兑奖：中奖者应按提示操作填写真实姓名、身份证号、手机号码、联系地址，康之味公司在获取中奖者的真实联系方式后将在3个工作日内与中奖者联系，在确认中奖者联系信息后，康之味公司将在10个工作日安排给消费者兑奖，奖品需中奖者本人签收，中奖者签收时需提供本人的身份证复印件给至康之味公司并签字确认签收。<br />
        2、红包奖兑奖：中奖者按页面指引提示操作领取红包，自收到微信服务红包领取通知起应于24小时内完成红包领取，逾期失效造成的兑奖失败将视为自动放弃兑奖机会。<br />
        3、圈码电子权益说明及领取规则：<br />
        <div v-html="ruleText"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { qmActivityRule } from '@/api/index'

export default {
  name: 'ActivityRules',
  data() {
    return {
      ruleText: ''
    }
  },
  methods: {
    getQmActivityRule() {
      qmActivityRule().then(({ data }) => {
        this.ruleText = data.ruleText || ''
      })
    }
  },
  created() {
    this.getQmActivityRule()
  }
}
</script>

<style lang="scss" scoped>
.m-activity-rules {
  position: relative;
  width: 695px;
  margin: 40px auto 0;
  height: 827px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: rgb(57, 127, 245, 0.38);
  border-radius: 10px;
  .activity-rules__body {
    position: absolute;
    top: 26px;
    left: 40px;
    right: 40px;
    bottom: 38px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 6px;
    -webkit-overflow-scrolling: touch;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 38px;
    height: 30px;
    z-index: 10;
    background-image: linear-gradient(
      0,
      rgb(38, 87, 190),
      rgb(38, 102, 190, 0)
    );
  }
  .activity-rules__cont {
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    h3 {
      margin-bottom: 5px;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }
    h4 {
      font-weight: bold;
    }
    b {
      display: block;
      margin-top: 6px;
    }
  }
}
</style>
