export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export function isFunction(val) {
  return typeof val == 'function'
}
export function getSessionStorage(key) {
  let val = sessionStorage.getItem(key)
  try {
    return JSON.parse(val)
  } catch {
    return val
  }
}
/* 是否手机号码*/
export function isPhone(value, callback) {
  const reg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/
  if (value == '' || value == undefined || value == null) {
    callback(false)
  } else {
    if (!reg.test(value) && value != '') {
      callback(false)
    } else {
      callback(true)
    }
  }
}
