<template>
  <van-popup
    v-model="show"
    :close-on-click-overlay="false"
    class="m-dialog"
    position="bottom"
  >
    <div
      class="dialog__main"
      v-bg="{
        remoteSrc: assets.rulesImg,
        localSrc: require('@/assets/qcode-activity-rules.png')
      }"
    >
      <div class="dialog__cont">
        <div class="p1">
          1、“抽奖赢好礼”活动是“{{
            setting.title
          }}”活动以外的单独的礼品赠送活动，您可根据自身的兴趣喜好或个人需求自愿选择是否参与。<br />
          2、如您选择参与“抽奖赢好礼”活动，请您认真阅读并了解本活动规则。您的参与将视为您理解并同意遵守本规则。<br />
          3、“抽奖赢好礼”活动区域为中国大陆地区。<br />
          4、“抽奖赢好礼”活动包括“饿了么、京东、同程旅行、淘宝”等诸多第三方电子权益礼品，具体礼品信息及领取使用规则您可以通过阅读本规则第8条获得。如与第三方向您公示的内容不一致，则以第三方向您公示的内容为准。<br />
          5、如您自愿决定参与“抽奖赢好礼”活动，请您返回到“抽奖赢好礼“活动页面，根据页面提示点击“点击领取”按钮，系统会随机赠送您一份电子权益礼品，您可根据自身的兴趣喜好或个人需求决定是否领取该电子权益礼品。如您决定领取，请您根据页面提示填写相关信息进行领取。您可以在“抽奖赢好礼“活动页面的“中奖记录”中查阅您成功领取礼品的记录。<br />
          6、“抽奖赢好礼”活动将随机给予您3次参与机会，祝您好运连连。<br />
          7、“抽奖赢好礼”活动由北京尚杰智选科技有限公司提供技术支持，活动咨询热线：400-815-9210（活动期间周一至周五09:00-18:00，法定节假日除外）；<br />
          8、电子权益礼品及领取规则：<br />
          <div v-html="ruleText"></div>
        </div>
      </div>
      <div class="close" @click="close"></div>
    </div>
  </van-popup>
</template>

<script>
import { mapGetters } from 'vuex'
import setting from '@/setting.js'
import { qmActivityRule } from '@/api/index'

export default {
  name: 'QcodeActivityRules',
  computed: {
    ...mapGetters(['assets'])
  },
  data() {
    return {
      show: false,
      ruleText: '',
      setting
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    getQmActivityRule() {
      qmActivityRule().then(({ data }) => {
        this.ruleText = data.ruleText || ''
      })
    }
  },
  created() {
    this.getQmActivityRule()
  }
}
</script>

<style lang="scss" scoped>
.m-dialog {
  width: 100%;
  height: 100vh;
  //background: url('../../assets/qcode-activity-rules.png') no-repeat center top / 100% auto;
  background-color: transparent;
  .dialog__main {
    padding: 327px 98px 0;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    color: #333;
    font-size: 24px;
    .close {
      margin: 60px auto 0;
      width: 48px;
      height: 48px;
      background: url('../../assets/close.png') no-repeat center top / 100% 100%;
    }
  }
  .dialog__cont {
    height: 640px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .p1 {
      font-size: 24px;
      color: #333;
    }
  }
}
</style>
