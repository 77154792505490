import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/auth/auth.vue'
import { addWxConfig } from '@/utils/wxConfig.js'
import setting from '@/setting.js'

// 解决当前路由跳转当前路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
console.log('VueRouter', VueRouter.prototype)
Vue.use(VueRouter)

const routes = [
  {
    path: '/:qrCode/:projectCode',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/front',
    name: 'Front',
    component: () =>
      import(/* webpackChunkName: "front" */ '../views/front/front.vue')
  },
  {
    path: '/middle',
    name: 'Middle',
    component: () =>
      import(/* webpackChunkName: "middle" */ '../views/middle/middle.vue')
  },
  {
    path: '/back',
    name: 'Back',
    component: () =>
      import(/* webpackChunkName: "back" */ '../views/back/back.vue')
  },
  {
    path: '/activityStatus',
    name: 'ActivityStatus',
    component: () =>
      import(
        /* webpackChunkName: "activityStatus" */ '../views/activity-status/activity-status'
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/' + process.env.VUE_APP_ROUTER_BASE,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || setting.title
  next()
})

router.afterEach(to => {
  if (to.name != 'Auth') {
    addWxConfig()
  }
})

export default router
