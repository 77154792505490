import { queryLotteryNumber, queryQrcodeScanFirstTime } from '@/api/index.js'
import { setSessionStorage } from '@/utils/storage.js'

const drawMixin = {
  data() {
    return {
      m_disabled: false,
      m_restLotteryNumber: '',
      m_initQueryLotteryNumber: false
    }
  },
  methods: {
    onOpenDialog(refName) {
      this.$refs[refName].open()
    },
    $_drawAfter() {
      // 查询剩余抽奖次数
      // console.log('查询剩余抽奖次数');
      // this.$_getQueryLotteryNumber();
    },
    $_onStartTurntable() {
      if (this.$route.name == 'Middle') {
        setSessionStorage('isMiddlePageDraw', true)
      }
    },
    // 查询剩余抽奖次数
    $_getQueryLotteryNumber() {
      return queryLotteryNumber().then(({ data }) => {
        this.m_restLotteryNumber = data.restLotteryNumber
        if (this.m_restLotteryNumber == 0) {
          this.m_disabled = true
        }
      })
    }
  },
  created() {
    console.log('mixin')
    if (this.m_initQueryLotteryNumber) {
      // 查询剩余抽奖次数
      this.$_getQueryLotteryNumber()
    }
  }
}

const qrcodeScanMixin = {
  data() {
    return {
      m_qrcodeScanData: {}
    }
  },
  methods: {
    // 二维码首次使用时间
    $_getQueryQrcodeScanFirstTime(flag = false) {
      return queryQrcodeScanFirstTime().then(({ data }) => {
        let { isFirstTime, pseudoMode } = data
        this.m_qrcodeScanData = data
        setSessionStorage('isFirstTime', isFirstTime)
        setSessionStorage('pseudoMode', pseudoMode)
        // pseudoMode 判断活动是否结束
        // isFirstTime 当前项目码是否为首次抽奖
        let skipName = ''
        if (pseudoMode || !isFirstTime) {
          skipName = 'Back'
          if (flag) {
            setSessionStorage('isTrackingBack', true)
            setSessionStorage('isEnd', true)
          }
        } else if (isFirstTime) {
          skipName = 'Front'
        }
        if (this.$route.name != skipName && this.$route.name != 'Back') {
          this.$router.push({
            name: skipName
          })
        }
      })
    }
  }
}

export { drawMixin, qrcodeScanMixin }
