import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Popup, Checkbox } from 'vant'
import scroll from 'vue-seamless-scroll'
// v-bg
import bg from './plugins/bg'
// 埋点插件
import tracking from './plugins/tracking'

Vue.config.productionTip = false

Vue.use(bg)
Vue.use(Popup)
Vue.use(Checkbox)
Vue.use(scroll)
Vue.use(tracking, {
  router
})

// 自动注册components目录全局组件
function autoRegisterComponents(r) {
  r.keys().forEach(path => {
    let component = r(path).default
    Vue.component(component.name, component)
  })
}
autoRegisterComponents(require.context('./components', true, /\.vue$/))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
