<template>
  <div class="m-helper" :class="className">
    <!-- 问题咨询 -->
    <p class="helper__item" @click="onQuestion()">
      <img src="@/assets/wtzx.png" width="100%" alt="问题咨询" />
    </p>
    <!-- 隐私协议 -->
    <p class="helper__item" @click="onProtocol()">
      <img src="@/assets/yssm.png" width="100%" alt="隐私协议" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'Helper',
  props: {
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  data() {
    return {}
  },
  computed: {
    className() {
      return [
        this.direction == 'horizontal'
          ? 'm-helper--horizontal'
          : 'm-helper--vertical'
      ]
    }
  },
  methods: {
    onQuestion() {
      var openId = sessionStorage.getItem('openId')
      var projectCode = sessionStorage.getItem('projectCode')
      window.location.href = `${process.env.VUE_APP_QUESTION_URL}?channelId=${projectCode}&titleName=${process.env.VUE_APP_QUESTION_TITLE}&openId=${openId}`
    },
    onProtocol() {
      window.location.href = process.env.VUE_APP_PRIVACYSTATEMENT_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.m-helper {
  display: flex;
  justify-content: center;
  .helper__item {
    width: 142px;
    height: 30px;
    img {
      display: block;
    }
  }
}
.m-helper--horizontal {
  padding: 53px 0 70px;
  .helper__item {
    margin: 0 64px;
  }
}
.m-helper--vertical {
  flex-direction: column;
  align-items: center;
  padding: 35px 0 30px;
  .helper__item {
    margin: 15px 0;
  }
}
</style>
