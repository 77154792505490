<template>
  <div class="u-draw-number">
    您有<span class="p1">{{ num }}</span
    >次抽奖机会
  </div>
</template>
<script>
import { queryLotteryNumber } from '@/api/index.js'

export default {
  name: 'DrawNumber',
  data() {
    return {
      num: null
    }
  },
  methods: {
    getQueryLotteryNumber() {
      queryLotteryNumber().then(({ data }) => {
        this.num = data.restLotteryNumber
      })
    }
  },
  created() {
    this.$nextRequest(this.getQueryLotteryNumber)
  }
}
</script>
<style scoped lang="scss">
.u-draw-number {
  text-align: center;
  font-size: 24px;
  color: #fff;
  .p1 {
    color: #fed831;
  }
}
</style>
