var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({directives:[{name:"bg",rawName:"v-bg",value:({
    remoteSrc: _vm.prizePlateConfig.outerImg,
    localSrc: require('@/assets/turntable-outer.png')
  }),expression:"{\n    remoteSrc: prizePlateConfig.outerImg,\n    localSrc: require('@/assets/turntable-outer.png')\n  }"}],staticClass:"turntable"},'div',_vm.$attrs,false),[_c('div',{staticClass:"turntable-prize"},[_c('ul',{directives:[{name:"bg",rawName:"v-bg",value:({
        remoteSrc: _vm.prizePlateConfig.innnerImg,
        localSrc: require('@/assets/turntable-inner.png')
      }),expression:"{\n        remoteSrc: prizePlateConfig.innnerImg,\n        localSrc: require('@/assets/turntable-inner.png')\n      }"}],staticClass:"turntable__inner",class:_vm.animatClass,style:([_vm.animatEnd])},_vm._l((_vm.prizeList),function(item,index){return _c('li',{key:index,style:(item.style)},[_c('img',{attrs:{"src":item.src,"width":"100%"}})])}),0),(_vm.canClickStartBtn)?_c('div',{directives:[{name:"tracking",rawName:"v-tracking",value:(_vm.tracking),expression:"tracking"},{name:"bg",rawName:"v-bg",value:({
        remoteSrc: _vm.prizePlateConfig.pointImg,
        localSrc: require('@/assets/turntable-pointer.png')
      }),expression:"{\n        remoteSrc: prizePlateConfig.pointImg,\n        localSrc: require('@/assets/turntable-pointer.png')\n      }"}],staticClass:"start-btn",on:{"click":_vm.onStart}}):_c('div',{directives:[{name:"tracking",rawName:"v-tracking",value:(_vm.tracking),expression:"tracking"},{name:"bg",rawName:"v-bg",value:({
        remoteSrc: _vm.prizePlateConfig.pointImg,
        localSrc: require('@/assets/turntable-pointer.png')
      }),expression:"{\n        remoteSrc: prizePlateConfig.pointImg,\n        localSrc: require('@/assets/turntable-pointer.png')\n      }"}],staticClass:"start-btn"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }