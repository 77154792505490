import request from '@/utils/request'

//获取抽奖画面图片和抽奖规则
export function queryTurntableImagesAndSweepstakesRules(data) {
  return request({
    url: '/luckyDraw/queryTurntableImagesAndSweepstakesRulesOfDouble',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}

//发起前置转盘抽奖
export function drawRecordPre(data) {
  return request({
    url: '/luckyDraw/drawRecordPre',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}

//发起中后置转盘抽奖
export function drawRecord(data) {
  return request({
    url: '/luckyDraw/drawRecordAfter',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}

//红牛中奖记录
export function queryPersonalCenter(data) {
  return request({
    url: '/bonus/queryPersonalCenter',
    method: 'post',
    data,
    imgPathAddDomain: true
  })
}

//圈码中奖记录
export function queryPrizeLog(data) {
  return request({
    url: '/bonus/queryPrizeLog',
    method: 'post',
    data
  })
}

//查询中奖名单
export function queryDrawRecord(data) {
  return request({
    url: '/luckyDraw/queryDrawRecord',
    method: 'post',
    data
  })
}

//领取红包
export function receiveRedPacket(data) {
  return request({
    url: '/luckyDraw/receiveRedPacket',
    method: 'post',
    data
  })
}

//领取红包DouMi
export function receiveRedPacketDouMi(data) {
  return request({
    url: '/luckyDraw/receiveRedPacketByDouMi',
    method: 'post',
    data
  })
}

//领取红包V3
export function receiveRedPacketV3(data) {
  return request({
    url: '/luckyDraw/receiveRedPacketV3',
    method: 'post',
    data
  })
}

//查询剩余抽奖次数
export function queryLotteryNumber(data) {
  return request({
    url: '/luckyDraw/LuckDrawNumber',
    method: 'post',
    data
  })
}

//二维码首次使用时间
export function queryQrcodeScanFirstTime(data) {
  return request({
    url: '/bonus/queryQrcodeScanFirstTime',
    method: 'post',
    data
  })
}

// 圈码活动规则
export function qmActivityRule(data) {
  return request({
    url: '/luckyDraw/queryActivityRule',
    method: 'post',
    data
  })
}

// 查询中奖记录
export function queryWinningRecord(data) {
  return request({
    url: '/bonus/queryWinningRecord',
    method: 'post',
    data
  })
}

// 查询实物奖链接
export function selectPrizeUrl(data) {
  return request({
    url: '/bonus/selectPrizeUrl',
    method: 'post',
    data
  })
}
