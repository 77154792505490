<template>
  <div class="container" v-tracking="{ userVisit: '20' }">
    <div class="m-activity-rules">
      <div class="activity-rules__body">
        <div class="activity-rules__cont">
          <h3>揭盖扫码有奖活动规则</h3>
          活动主题：揭盖扫码有奖<br />
          活动时间：即日起至2022年12月31日止<br />
          活动范围：中国大陆地区<br />
          产品范围：盐典饮料促销装<br />

          为感谢广大消费者的支持，即日起至2022年12月31日止，凡在活动开展地区购买凡购买盐典饮料促销装产品，开盖发现二维码，用手机微信扫描该二维码（识别率99%），即有机会赢取奖品。<br />
          奖品总数120000000个，奖项设置：<br />
          特等奖：黄金酱酒-高尔夫（价值1098元），共100个，中奖率约0.000083%；<br />
          红包奖：88元红包，共888个，中奖率约0.00074%；<br />
          红包奖：8.8元红包，共20000个，中奖率约0.017%；<br />
          红包奖：0.3元红包，共18000000个，中奖率约15.00%；<br />
          圈码好礼：电子奖券，共101979012个，中奖率约84.98%。<br />

          完整活动规则，请进入转盘页查看
        </div>
      </div>
    </div>
    <!-- 引导 -->
    <div class="guide" v-if="showGuide"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  checkProject,
  checkCode,
  wxCode,
  userinfo,
  locationParams,
  location
} from '@/api/common.js'
import { qrcodeScanMixin } from '@/mixins/index'
import { setSessionStorage } from '@/utils/storage.js'
import setting from '@/setting.js'

const wx = require('weixin-js-sdk')

export default {
  name: 'Auth',
  mixins: [qrcodeScanMixin],
  data() {
    return {
      code: '',
      isLoading: false,
      showGuide: false
    }
  },
  computed: {
    ...mapGetters(['projectCode', 'qrCode', 'isLocation'])
  },
  watch: {
    '$route.query.code': {
      handler: function (code) {
        let { projectCode, qrCode } = this.$route.params
        if (projectCode && qrCode) {
          this.$store.commit('projectCode', projectCode)
          this.$store.commit('qrCode', qrCode)
        }

        if (code) {
          this.isLoading = true
          this.code = code
          let redirect =
            window.location.protocol +
            '//' +
            window.location.host +
            '' +
            window.location.pathname
          setSessionStorage('_redirect', redirect)
          // 获取用户信息
          this.getUserinfo()
        } else {
          if (!projectCode && !qrCode) {
            return
          }
          // 进入页面埋点
          this.$sendTracking({
            projectCode,
            qrCode,
            userVisit: 13
          })
          this.getCheckProject()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 获取项目校验
    getCheckProject() {
      checkProject({
        projectCode: this.projectCode
      }).then(({ data }) => {
        //获取微信sdk信息
        this.getWxJSSDKConfig({
          projectCode: this.projectCode
        })
        //0=活动未开始，1=活动已开始，2=活动已结束，3=活动不存在，4=活动状态未知
        if (data.status == 1) {
          let isLocation = data.location
          this.$store.commit('isLocation', isLocation)
          // 获取码
          this.getCheckCode()
        } else {
          //0未开始 2已结束
          this.$router.push({
            name: 'ActivityStatus',
            query: {
              status: data.status
            }
          })
        }
      })
    },
    // 获取码
    getCheckCode() {
      checkCode({
        qrCode: this.qrCode
      }).then(({ data }) => {
        // 0正确数据 1已关联待激活 2已激活 待进入活动10 活动99 无效
        if (data.status == 10) {
          // 获取微信code
          this.getWxCode()
        } else {
          data._title = setting.title
          let queryString = ''
          for (let key in data) {
            queryString += `${key}=${encodeURI(data[key])}&`
          }
          queryString = queryString.substring(0, queryString.lastIndexOf('&'))

          if (data.verifyPageType == 1) {
            window.location.href = `${process.env.VUE_APP_QCODESTATE_URL}?${queryString}`
          } else if (data.verifyPageType == 0) {
            window.location.href = `${process.env.VUE_APP_CODESTATE_URL}?${queryString}`
          }
        }
      })
    },
    // 获取微信code
    getWxCode() {
      wxCode({
        projectCode: this.projectCode,
        qrCode: this.qrCode
      }).then(({ data }) => {
        console.log(data)
        let appId = data.appID
        let redirectUri = encodeURIComponent(window.location.href.split('#')[0])
        let scope = data.scope
        let httpUrl = data.oauthCodePrefix
        let state = this.projectCode + '/' + this.qrCode
        let url = `${httpUrl}appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
        console.log(url)
        window.location.href = url
      })
    },
    // 获取用户信息
    getUserinfo() {
      userinfo({
        code: this.code
      })
        .then(({ data }) => {
          this.$store.commit('openId', data.openId)
          // 获取微信定位所需参数
          this.getLocationParams()
        })
        .catch(() => {
          this.showGuide = true
        })
    },
    // 获取微信sdk信息
    getWxJSSDKConfig(params, cb) {
      locationParams(params).then(({ data }) => {
        let { timestamp, appId, nonceStr, signature } = data
        console.log(wx)
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名，见附录1
          jsApiList: ['getLocation', 'hideOptionMenu'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(() => {
          wx.hideOptionMenu()
          cb && cb()
        })
      })
    },
    // 获取微信定位所需参数
    getLocationParams() {
      console.log('get isLocation', this.isLocation)
      if (this.isLocation) {
        this.getWxJSSDKConfig(
          {
            projectCode: this.projectCode
          },
          () => {
            wx.getLocation({
              debug: true,
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: ({ latitude, longitude }) => {
                this.getLocation(latitude, longitude)
              },
              cancel: () => {
                console.log('取消 getLocation')
                this.getLocation()
              },
              fail: () => {
                console.log('失败 getLocation')
                this.getLocation()
              }
            })
          }
        )
      } else {
        this.toWhichPage()
      }
    },
    // 根据经纬度获取位置信息
    getLocation(lat = '', lng = '') {
      location({
        lat,
        lng
      }).then(() => {
        this.toWhichPage()
      })
    },
    //去哪个转盘
    toWhichPage() {
      this.$_getQueryQrcodeScanFirstTime(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-top: 578px;
  width: 100%;
  height: 1624px;
  background: url('../../assets/auth-bg.png') no-repeat top center / 100% auto;
  .guide {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 750px;
    height: 364px;
    background: url('../../assets/guide.gif') no-repeat top center / 100% auto;
    pointer-events: none;
  }
}
.m-activity-rules {
  position: relative;
  width: 695px;
  margin: 40px auto 0;
  height: 750px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: rgb(57, 127, 245, 0.38);
  border-radius: 10px;
  .activity-rules__body {
    position: absolute;
    top: 26px;
    left: 40px;
    right: 40px;
    bottom: 38px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 6px;
    -webkit-overflow-scrolling: touch;
  }
  .activity-rules__cont {
    font-size: 24px;
    line-height: 34px;
    color: #fff;
    h3 {
      margin-bottom: 15px;
      font-size: 28px;
      text-align: center;
      font-weight: bold;
    }
    h4 {
      font-weight: bold;
    }
    b {
      display: block;
      margin-top: 6px;
    }
  }
}
</style>
