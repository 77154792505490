import axios from 'axios'
import { Toast } from 'vant'
import Qs from 'qs'
import { imgPathAddDomain } from '@/utils/index.js'
import { getSessionStorage } from '@/utils/storage.js'
//import store from '@/store';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  //baseURL: 'http://rap2api.taobao.org/app/mock/294648/scan',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8'
    config.data = Qs.stringify(config.data)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    console.log('response', res)
    res.msg = res.msg || '网络异常，请稍后扫码重试'
    if (res.code != 200) {
      // 登陆过期
      if (res.code == 900) {
        Toast({
          message: '登录状态超时，请重新扫码进入',
          duration: 0,
          forbidClick: true
        })
        return Promise.reject(res)
      }
      // 微信code无效
      if (res.code == 899) {
        // 重新拉取授权
        window.location.href = getSessionStorage('_redirect')
        return Promise.reject(res)
      }
      // 该码已被他人使用 557
      // 该码已被自己使用 556
      if (res.code == 557 || res.code == 556) {
        Toast({
          message: res.msg,
          duration: 800,
          forbidClick: true,
          onClose: () => {
            window.location.reload()
          }
        })
        return Promise.reject(res)
      }
      Toast({
        message: res.msg,
        forbidClick: true
      })
      return Promise.reject(res.msg)
    } else {
      if (response.config.imgPathAddDomain) {
        return imgPathAddDomain(res)
      } else {
        return res
      }
    }
  },
  error => {
    console.log('err: ' + error) // for debug
    let message = '网络异常，请稍后扫码重试'
    if (error && error.message.indexOf('timeout') !== -1) {
      message = '网络超时，请稍后扫码重试'
    }
    Toast({
      message,
      forbidClick: true
    })
    return Promise.reject(error)
  }
)

export default service
