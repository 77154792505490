<template>
  <div>
    <div class="m-handler2 m-handler2-1">
      <div
        class="handler__btn handler__btn--left"
        @click="onOpenDialog('winningRecord')"
        v-tracking="{ userVisit: '8' }"
      >
        圈码中奖记录
      </div>
      <div
        class="handler__btn handler__btn--right"
        @click="onOpenDialog('qcode-activity-rules')"
      >
        圈码活动规则
      </div>
    </div>
    <!-- 圈码活动规则 -->
    <qcode-activity-rules ref="qcode-activity-rules"></qcode-activity-rules>
    <!-- 中奖记录 -->
    <winningRecord ref="winningRecord"></winningRecord>
  </div>
</template>

<script>
export default {
  name: 'QcodeBar',
  props: {},
  data() {
    return {}
  },
  methods: {
    onOpenDialog(refName) {
      this.$refs[refName].open()
    }
  }
}
</script>

<style lang="scss" scoped>
.m-handler2 {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .handler__btn {
    padding: 0 20px;
    height: 54px;
    background: linear-gradient(168deg, #00a3e9, #0f7ac5);
    border: 2px solid #f3d239;
    box-shadow: 1px 4px 7px 0px #154a84;
    border-radius: 27px;
    font-weight: bold;
    color: #fcf59f;
    font-size: 24px;
    text-align: center;
    line-height: 54px;
  }
}
.m-handler2-1 {
  padding: 0;
  .handler__btn--left {
    border-radius: 0px 27px 27px 0px;
  }
  .handler__btn--right {
    border-radius: 27px 0px 0px 27px;
  }
}
</style>
