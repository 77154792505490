<template>
  <div
    class="m-winners"
    v-bg="{
      remoteSrc: assets.prizeFormImg,
      localSrc: require('@/assets/winners.png')
    }"
  >
    <vue-seamless-scroll
      :data="winners"
      :class-option="classOption"
      class="winners__box"
    >
      <ul class="winners__ul">
        <li class="winners__item" v-for="(item, index) in winners" :key="index">
          <!-- <span class="winners__nickname">{{ item.nickName }}</span> -->
          <span class="winners__prize">{{ item.prizeName }}</span>
          <span class="winners__date">{{ item.createTime }}</span>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { queryDrawRecord } from '@/api/index.js'

export default {
  name: 'Winners',
  computed: {
    classOption() {
      return {
        step: 0.5,
        limitMoveNum: this.winners.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        base: '22.5',
        rotate: 0,
        openTouch: false
      }
    },
    ...mapGetters(['assets'])
  },
  data() {
    return {
      winners: []
    }
  },
  methods: {
    getWinners() {
      queryDrawRecord().then(({ data }) => {
        this.winners = data
      })
    }
  },
  created() {
    this.getWinners()
  }
}
</script>

<style lang="scss" scoped>
.m-winners {
  margin: 0 auto;
  padding: 85px 55px 30px;
  width: 500px;
  height: 257px;
  background-image: url('../../assets/winners.png');
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  .winners__box {
    height: 100%;
    overflow: hidden;
  }
  .winners__item {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 24px;
      color: #333;
    }
    // .winners__nickname {
    //   flex-basis: 80px;
    // }
    .winners__prize {
      padding: 0 10px;
      flex-grow: 0;
      flex-shrink: 1;
    }
    .winners__date {
      flex-basis: 150px;
    }
  }
}
</style>
